import React from 'react';
import SEO from 'components/seo';

// Component
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';
import Hero from 'components/molecules/Hero/Hero';

// Data
import KleurenPaletData from 'data/KleurenPaletPage.json';

const KleurenPaletPage = () => (
  <>
    <SEO title="Kleurenpalet" />
    <TextImageBlock
      data={KleurenPaletData.intro}
    />
    <Hero
      modifier="inPage"
      {...KleurenPaletData.inPageHero}
    />
  </>
);
export default KleurenPaletPage;
